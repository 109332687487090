import assign from 'lodash/assign';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { wrapProtectedActions } from '../../../../actions/auth';
import {
  favoriteContainerItem,
  favoriteCurrentlyPlayingItem,
  unFavoriteContainerItem,
  unFavoriteCurrentlyPlayingItem,
} from '../../../../actions/favorites';
import { selectIsDiscord, selectIsMobile } from '../../../../selectors/app';
import FavoriteStyleContext from './context/FavoriteStyleContext';
import iconCss from './favorite-icon.module.scss';

export function FavoriteIconControl({
  fill,
  hoverFill,
  viewBox,
  isContainerItemFavorite,
  isFollowing,
  showIconLabel,
  guideId,
  children,
  isOptionsController,
  isOption,
  isMobile,
  isDiscord,
  actions,
  handleFavoriteToggle,
  width,
  height,
  onFavoriteChange,
}) {
  const [isHovering, setIsHovering] = useState(false);
  const canControlHoveringLocally = !(isMobile || isDiscord);
  // This effect prevents the icon from changing immediately after toggling favorite due to hover.
  // Hover state is reset on next mouseEnter.
  useEffect(() => setIsHovering(false), [isFollowing]);
  const { handleMouseEnter, handleMouseLeave } =
    useContext(FavoriteStyleContext);

  function handleFavoriteAction() {
    onFavoriteChange?.();

    if (handleFavoriteToggle) {
      handleFavoriteToggle();

      return;
    }

    if (isContainerItemFavorite) {
      if (isFollowing) {
        actions.unFavoriteContainerItem({ guideId });
      } else {
        actions.favoriteContainerItem({ guideId });
      }
      return;
    }

    if (isFollowing) {
      actions.unFavoriteCurrentlyPlayingItem(guideId);
    } else {
      actions.favoriteCurrentlyPlayingItem(guideId);
    }
  }

  const containerProps = {
    'data-testid': `favorite-icon-control-${guideId}`,
    onClick: handleFavoriteAction,
    onMouseEnter: canControlHoveringLocally ? () => setIsHovering(true) : noop,
    onMouseLeave: canControlHoveringLocally ? () => setIsHovering(false) : noop,
  };

  if (isOptionsController) {
    assign(containerProps, {
      onClick: noop,
      onMouseEnter: handleMouseEnter || noop,
      onMouseLeave: handleMouseLeave || noop,
    });
  }

  if (!isOption) {
    containerProps.className = iconCss.container;
  }

  return (
    <div {...containerProps}>
      {children({
        showIconLabel,
        isFollowing,
        isHovering,
        hoverFill,
        fill,
        viewBox,
        width,
        height,
      })}
    </div>
  );
}

FavoriteIconControl.propTypes = {
  // inherited
  guideId: PropTypes.string,
  children: PropTypes.func.isRequired,
  showIconLabel: PropTypes.bool,
  isOptionsController: PropTypes.bool,
  isOption: PropTypes.bool,
  isContainerItemFavorite: PropTypes.bool,
  fill: PropTypes.string,
  hoverFill: PropTypes.string,
  viewBox: PropTypes.string,
  handleFavoriteToggle: PropTypes.func,
  isFollowing: PropTypes.bool.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  onFavoriteChange: PropTypes.func,

  // mapStateToProps
  isMobile: PropTypes.bool.isRequired,
  isDiscord: PropTypes.bool.isRequired,

  // mapDispatchToProps
  actions: PropTypes.exact({
    favoriteCurrentlyPlayingItem: PropTypes.func.isRequired,
    unFavoriteCurrentlyPlayingItem: PropTypes.func.isRequired,
    favoriteContainerItem: PropTypes.func.isRequired,
    unFavoriteContainerItem: PropTypes.func.isRequired,
  }).isRequired,
};

FavoriteIconControl.defaultProps = {
  isOption: false,
  isOptionsController: false,
};

function mapStateToProps(state) {
  return {
    isMobile: selectIsMobile(state),
    isDiscord: selectIsDiscord(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      wrapProtectedActions({
        favoriteCurrentlyPlayingItem,
        unFavoriteCurrentlyPlayingItem,
        favoriteContainerItem,
        unFavoriteContainerItem,
      }),
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FavoriteIconControl);
