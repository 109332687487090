import PropTypes from 'prop-types';
import useFavoriteControlSettings from 'src/common/hooks/useFavoriteControlSettings';
import FavoriteControlWithOptions from './FavoriteControlWithOptions';
import FavoriteIcon from './FavoriteIcon';
import FavoriteIconControl from './FavoriteIconControl';
import FavoriteStyleContext from './context/FavoriteStyleContext';

export default function FavoriteControl({
  profileGuideId,
  fill,
  hoverFill,
  viewBox,
  iconClassName,
  showIconLabel,
  unFavoritedClassName,
  handleFavoriteToggle,
  width,
  height,
  isFollowing: initialIsFollowing,
}) {
  const { guideId, isFollowing, showControlWithOptions, onFavoriteChange } =
    useFavoriteControlSettings(profileGuideId, initialIsFollowing);

  return (
    <FavoriteStyleContext.Provider
      value={{ iconClassName, unFavoritedClassName }}
    >
      {showControlWithOptions ? (
        <FavoriteControlWithOptions
          isFollowing={isFollowing}
          showIconLabel={showIconLabel}
          fill={fill}
        />
      ) : (
        <FavoriteIconControl
          guideId={guideId}
          fill={fill}
          hoverFill={hoverFill}
          viewBox={viewBox}
          handleFavoriteToggle={handleFavoriteToggle}
          isFollowing={isFollowing}
          showIconLabel={showIconLabel}
          width={width}
          height={height}
          onFavoriteChange={onFavoriteChange}
        >
          {(props) => <FavoriteIcon {...props} />}
        </FavoriteIconControl>
      )}
    </FavoriteStyleContext.Provider>
  );
}

FavoriteControl.propTypes = {
  profileGuideId: PropTypes.string,
  fill: PropTypes.string,
  hoverFill: PropTypes.string,
  viewBox: PropTypes.string,
  iconClassName: PropTypes.string,
  showIconLabel: PropTypes.bool,
  unFavoritedClassName: PropTypes.string,
  handleFavoriteToggle: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  isFollowing: PropTypes.bool,
};
